import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Service} from '@worxs/cms/types/service';
import {environment} from '../../environments/environment';
import {map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  public service ?: Service;
  private path = '/services';
  private services$: BehaviorSubject<Service[]> = new BehaviorSubject<Service[]>([]);
  private defaultPopulate = 'service_subjects,image,additional';

  constructor(
    private http: HttpClient
  ) {
  }

  fetchAll(): Observable<Service[]> {
    const url = `${environment.backendEndpoint + this.path}?populate=${this.defaultPopulate}`;
    return this.http.get<{ data: Service[] }>(url)
      .pipe(
        map((response: any) => response.data),
        tap((services => this.services$.next(services)))
      );
  }

  getCachedServices$(): Observable<Service[]> {
    return this.services$.asObservable();
  }

  fetchOne(id: number): Observable<Service> {
    const url = `${environment.backendEndpoint + this.path}/${id}?populate=${this.defaultPopulate}`;

    return this.http.get<{ data: Service }>(url)
      .pipe(
        map(response => response.data),
        tap(service => {
          this.services$.subscribe(services => {
            ServiceService.upsertService(services, service);
            this.services$.next(services);
          })
        })
      );
  }

  /**
   * Given a list of services and a service, this method will either add the service to the list if it doesn't exist, or update the existing service.
   * @param services
   * @param service
   * @private
   */
  private static upsertService(services: Service[], service: Service) {
    const existingService = services.find(s => s.id === service.id);

    if (existingService) {
      const existingServiceIndex = services.findIndex(s => s.id === service.id);
      services.splice(existingServiceIndex, 1, service);

      return services;
    } else {
      services.push(service);
      return services;
    }
  }

  getServiceBySlug(slug: string): Observable<Service> {
    const url = `${environment.backendEndpoint + this.path}?filters[slug][$eq]=${slug}&populate=${this.defaultPopulate}`;

    return this.http.get<{ data: Service[] }>(url)
      .pipe(
        map(response => response.data),
        map(services => services[0]),
        tap(service => {
          const currentServices = this.services$.value;
          ServiceService.upsertService(currentServices, service);
          this.services$.next(currentServices);
        })
      );
  }

}
